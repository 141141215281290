import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { filter, Subject, takeUntil } from 'rxjs';

import { PrivaButtonModule } from '@priva/components/button';
import {
    PrivaFilterButtonsModule,
    PrivaFilterDefaultStorage,
    PrivaFilterModule,
    PrivaQueryableDataSource,
    PrivaQueryableDataSourceFactory,
    PrivaQueryUtils,
    PrivaSortDirection,
} from '@priva/components/filter';
import { PrivaLocalizationService } from '@priva/localization';
import { CropState, CropStateContainer, ExpandedCrop, selectExpandedCrops } from '@priva/masterdata';
import { PrivaTokens } from '@priva/styles/foundation';

import { nameof } from '@app/utilities';

import { CropWizardComponent } from '../crop-wizard/crop-wizard.component';
import { CropsFilterConfig } from './crops-filter.config';

@Component({
    selector: 'app-crops-filter',
    templateUrl: './crops-filter.component.html',
    styleUrls: ['./crops-filter.component.scss'],
    standalone: true,
    imports: [
        PrivaFilterModule,
        PrivaFilterButtonsModule,
        PrivaButtonModule,
        CropWizardComponent,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropsFilterComponent implements OnInit, OnDestroy {
    private readonly _localizationService = inject(PrivaLocalizationService);
    private readonly _store: Store<CropStateContainer> = inject(Store);

    private readonly _unsubscribe$ = new Subject<void>();
    private readonly _dataSourceFactory = new PrivaQueryableDataSourceFactory(new PrivaQueryUtils());

    private _filterConfig: CropsFilterConfig;

    @Output() dataSourceReady = new EventEmitter<PrivaQueryableDataSource<ExpandedCrop>>();

    protected readonly cropsListFilterConfig = CropsFilterConfig;
    protected readonly window = window;
    protected readonly breakpointSm = +PrivaTokens.BreakpointSm.replace('px', '');

    public filterButtonsConfig = [];
    public dataSource: PrivaQueryableDataSource<ExpandedCrop>;
    public showCropWizard = false;

    public ngOnInit(): void {
        // Make sure the translations are ready, before creating the configurations
        this._localizationService
            .translate('DUMMY')
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe(() => this.setFilterButtonsConfig());

        this.createDataSource();
    }

    public ngOnDestroy() {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    private createDataSource() {
        const storage = new PrivaFilterDefaultStorage(sessionStorage, 'priva.crop-performance.crops');
        this.dataSource = this._dataSourceFactory.create();
        this.dataSource.setData(
            this._store.select(selectExpandedCrops).pipe(
                takeUntil(this._unsubscribe$),
                filter((cropsWithLocation) => !!cropsWithLocation),
            ),
        );
        this.dataSource.setStorage(storage);
        this.dataSource.addSort({ name: PrivaSortDirection.ASCENDING });

        this.dataSource.addPropertyFilter(nameof<ExpandedCrop>('state'), [
            CropState.Current,
            CropState.Upcoming,
        ]);

        this.dataSourceReady.emit(this.dataSource);
    }

    private setFilterButtonsConfig() {
        this._filterConfig = new CropsFilterConfig(this._localizationService);
        this.filterButtonsConfig = [
            this._filterConfig.filterSearchConfig,
            this._filterConfig.filterSortConfig,
            this._filterConfig.filterChoiceStateConfig,
        ];
    }
}
