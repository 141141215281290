import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import dayjs from 'dayjs';

import { PrivaContentSectionModule } from '@priva/components/content-section';
import { PrivaTileModule } from '@priva/components/tile';
import { PrivaTitleModule } from '@priva/components/title';

@Component({
    selector: 'app-crops-anomalies',
    standalone: true,
    imports: [DatePipe, PrivaTitleModule, PrivaTileModule, PrivaContentSectionModule],
    templateUrl: './crops-anomalies.component.html',
    styleUrl: './crops-anomalies.component.scss',
})
export class CropsAnomaliesComponent {
    public date = dayjs().subtract(1, 'day').toString();
    public iconClass = 'fa-regular fa-calendar';
}
