<priva-title
    size="sm"
    title="{{ date | date: 'dd MMMM' }}"
    class="margin-bottom-4"
    theme="onlightgray"
    [iconCircle]="false"
    [iconClass]="iconClass"
/>

<priva-content-section>
    <priva-tile [hasHeader]="false">
        <p>Anomaly table to follow</p>
    </priva-tile>
</priva-content-section>
