<priva-content-section isCentered="true">
    @if (currentUpcomingCropsCount$ | async) {
        <priva-status-global title="{{ 'APP.CROPS.NO_CROPS.TITLE' | translate }}" [hasImage]="true">
            <priva-illustration illustrationName="general-empty-state" data-image />
        </priva-status-global>
    } @else {
        <priva-status-global
            title="{{ 'APP.CROPS.EMPTY.TITLE' | translate }}"
            subtitle="{{ 'APP.CROPS.EMPTY.SUBTITLE' | translate }}"
            [hasImage]="true"
        >
            <priva-illustration illustrationName="general-empty-state" data-image />
            <button
                priva-button
                importance="primary"
                iconClass="far fa-plus"
                iconState="left"
                theme="onwhite"
                (click)="showCropWizard = !showCropWizard"
            >
                {{ 'APP.CROPS.ADD' | translate }}
            </button>
        </priva-status-global>
    }
</priva-content-section>

@if (showCropWizard) {
    <app-crop-wizard (close)="showCropWizard = false" />
}
