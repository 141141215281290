import {
    PrivaFilterButtonsDisplayConfig,
    PrivaFilterChoiceConfig,
    PrivaFilterDialogDisplayConfig,
    PrivaFilterSearchConfig,
    PrivaFilterSortConfig,
    PrivaSortDirection,
} from '@priva/components/filter';
import { PrivaLocalizationService } from '@priva/localization';
import { CropState, ExtendedCrop } from '@priva/masterdata';

import { nameof } from '@app/utilities';

export class CropsFilterConfig {
    constructor(private readonly translateService: PrivaLocalizationService) {}

    public get filterSearchConfig(): PrivaFilterSearchConfig {
        return {
            id: 'search',
            type: 'search',
            title: 'APP.CROPS.FILTER.SEARCH_TITLE',
            placeholder: 'APP.CROPS.FILTER.SEARCH_PLACEHOLDER',
            options: [nameof<ExtendedCrop>('name')],
            searchOptions: {
                matchType: 'multiple',
            },
        };
    }

    public get filterSortConfig(): PrivaFilterSortConfig {
        return {
            id: 'sort',
            type: 'sort',
            title: 'APP.CROPS.FILTER.SORT_TITLE',
            options: [
                {
                    label: `<i class="fa fa-caret-down"></i> ${this.translateService.instant(
                        'APP.CROP.NAME',
                    )} (ASC)`,
                    field: nameof<ExtendedCrop>('name'),
                    order: PrivaSortDirection.ASCENDING,
                },
                {
                    label: `<i class="fa fa-caret-up"></i> ${this.translateService.instant(
                        'APP.CROP.NAME',
                    )} (DESC)`,
                    field: nameof<ExtendedCrop>('name'),
                    order: PrivaSortDirection.DESCENDING,
                },
                {
                    label: `<i class="fa fa-caret-down"></i> ${this.translateService.instant(
                        'APP.CROP.START_DATE',
                    )} (ASC)`,
                    field: nameof<ExtendedCrop>('startDateKey'),
                    order: PrivaSortDirection.ASCENDING,
                },
                {
                    label: `<i class="fa fa-caret-up"></i> ${this.translateService.instant(
                        'APP.CROP.START_DATE',
                    )} (DESC)`,
                    field: nameof<ExtendedCrop>('startDateKey'),
                    order: PrivaSortDirection.DESCENDING,
                },
                {
                    label: `<i class="fa fa-caret-down"></i> ${this.translateService.instant(
                        'APP.CROP.END_DATE',
                    )} (ASC)`,
                    field: nameof<ExtendedCrop>('endDateKey'),
                    order: PrivaSortDirection.ASCENDING,
                },
                {
                    label: `<i class="fa fa-caret-up"></i> ${this.translateService.instant(
                        'APP.CROP.END_DATE',
                    )} (DESC)`,
                    field: nameof<ExtendedCrop>('endDateKey'),
                    order: PrivaSortDirection.DESCENDING,
                },
            ],
        };
    }

    public get filterChoiceStateConfig(): PrivaFilterChoiceConfig {
        return {
            id: 'name',
            field: nameof<ExtendedCrop>('state'),
            title: 'APP.CROPS.FILTER.STATE_TITLE',
            type: 'filter',
            displayType: 'boxes',
            options: [
                {
                    id: 'current',
                    label: `<i class="far fa-seedling"></i> ${this.translateService.instant(
                        'APP.CROPS.FILTER.STATE_CURRENT',
                    )}`,
                    value: CropState.Current,
                },
                {
                    id: 'upcoming',
                    label: `<i class="far fa-hand-holding-seedling"></i> ${this.translateService.instant(
                        'APP.CROPS.FILTER.STATE_UPCOMING',
                    )}`,
                    value: CropState.Upcoming,
                },
                {
                    id: 'completed',
                    label: `<i class="far fa-box"></i> ${this.translateService.instant(
                        'APP.CROPS.FILTER.STATE_COMPLETED',
                    )}`,
                    value: CropState.Completed,
                },
            ],
        };
    }

    public static get filterButtonsDisplayConfig(): PrivaFilterButtonsDisplayConfig {
        return [
            {
                id: 'search',
                display: 'search',
                disabled: false,
            },
            {
                id: 'name',
                display: 'filter',
                disabled: false,
            },
        ];
    }

    public static get filterDialogDisplayConfig(): PrivaFilterDialogDisplayConfig {
        return {
            layout: {
                column: {
                    one: ['name', 'sort'],
                    two: [],
                },
            },
            labels: {
                button: 'APP.CROPS.FILTER.DIALOG.MORE',
                dialog: {
                    headerTitle: 'APP.CROPS.FILTER.DIALOG.TITLE',
                    footer: {
                        buttonClear: 'APP.CROPS.FILTER.DIALOG.RESET',
                        buttonShow: 'APP.CROPS.FILTER.DIALOG.SHOW',
                    },
                },
            },
        };
    }
}
