<priva-dialog
    headerIconClass="far fa-seedling"
    height="800"
    width="664"
    [title]="'APP.CROPS.ADD' | translate"
    [buttons]="[]"
    [hasFooter]="true"
    (close)="close.emit()"
>
    <priva-wizard-indicator
        data-dialog-header
        class="margin-top-2"
        [steps]="steps"
        [activeStepIndex]="activeStepIndex"
    />

    @switch (activeStepIndex) {
        @case (Step.basicInformation) {
            <app-crop-basic-information [crop]="crop" (validChange)="onBasicInformationValidChange($event)" />
        }
        @case (Step.location) {
            <app-crop-location [crop]="crop" (validChange)="onLocationValidChange($event)" />
        }
    }

    <priva-wizard-toolbar
        data-dialog-footer-end
        [steps]="steps"
        [activeStep]="activeStepIndex"
        [buttons]="buttons"
        (buttonClick)="buttonClick($event)"
    />
</priva-dialog>
