// kpi state
import { Dayjs } from 'dayjs';

export interface KpiDeviationsStateContainer {
    activeCropsKpiDeviations: KpiDeviationsState;
}

export interface KpiDeviationsState {
    cropsKpiDeviations: KpiDeviations[];
    cropKpiDeviationsRange: DateRequest;
    isLoading: boolean;
}

export interface KpiDeviations {
    request: Request;
    deviations: DeviationList[];
}

export interface Request {
    tenantId: string;
    cropId: string;
    localDate?: string;
    localYearWeek?: string;
    kpiIds?: string[];
}

export interface DeviationList {
    kpiId: string;
    value: number;
    deviation: number;
    isOutOfBand: boolean;
    hasTarget: boolean;
}

export const initialKpiDeviations: KpiDeviationsState = {
    cropsKpiDeviations: undefined,
    cropKpiDeviationsRange: undefined,
    isLoading: false,
};

// for handling errors
export interface KpiDeviationsError {
    error: any;
    cropId: string;
}

export type CropKpisResponse = KpiDeviations | KpiDeviationsError;

// date request pass from components to store
export interface DateRequest {
    dateRange: 'daily' | 'weekly';
    date: Dayjs;
}
