import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    inject,
    OnInit,
    signal,
    WritableSignal,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import isoWeek from 'dayjs/plugin/isoWeek';
import { DpDatePickerModule, ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import { take } from 'rxjs';

import { PrivaSegmentService } from '@priva/analytics/segment';
import { PrivaButtonModule } from '@priva/components/button';
import { PrivaContentHeaderModule } from '@priva/components/content-header';
import { PrivaContentSectionModule } from '@priva/components/content-section';
import { PrivaDatepickerModule } from '@priva/components/datepicker';
import { PrivaFormGroupModule } from '@priva/components/form-group';
import { PrivaHeaderModule } from '@priva/components/header';
import { PrivaLinkModule } from '@priva/components/link';
import { PrivaLoaderModule } from '@priva/components/loader';
import { PrivaPopoverModule } from '@priva/components/popover';
import { PrivaSelectionGroupItem, PrivaSelectionGroupModule } from '@priva/components/selection-group';
import { PrivaSpinnerModule } from '@priva/components/spinner';
import { PrivaTabListModule } from '@priva/components/tab-list';
import { PrivaTableModule } from '@priva/components/table';
import { PrivaTileModule } from '@priva/components/tile';
import { PrivaTitleModule } from '@priva/components/title';
import { PrivaLocalizationService, PrivaTranslateModule } from '@priva/localization';
import { PrivaToggleModule } from '@priva/utilities/toggle';

import {
    DateFormat,
    DatePickerService,
    DayFormat,
    DayMonthFormat,
    MonthFormat,
    MonthYearFormat,
    YearFormat,
} from '@app/utilities';

import { DateRequest, loadKPIsIfNeeded, selectIsLoading } from '../state';
import { ValueWithDeviationComponent } from '../value-with-deviation/value-with-deviation.component';
import { selectCurrentCropsEarliestStartDate, selectDashboardData } from './dashboard.selectors';

dayjs.extend(isoWeek);

export enum DateRange {
    DAILY = 'daily',
    WEEKLY = 'weekly',
}

type DateRangeType = 'daily' | 'weekly';

const list = [
    {
        label: 'APP.DASHBOARD.DAILY.SELECT',
        id: DateRange.DAILY,
    },
    {
        label: 'APP.DASHBOARD.WEEKLY.SELECT',
        id: DateRange.WEEKLY,
    },
];

@Component({
    selector: 'app-crops-dashboard',
    standalone: true,
    imports: [
        PrivaContentSectionModule,
        PrivaTitleModule,
        PrivaTableModule,
        PrivaTileModule,
        PrivaButtonModule,
        PrivaTranslateModule,
        PrivaLinkModule,
        ValueWithDeviationComponent,
        PrivaPopoverModule,
        PrivaToggleModule,
        PrivaHeaderModule,
        PrivaContentHeaderModule,
        PrivaTabListModule,
        PrivaSelectionGroupModule,
        PrivaFormGroupModule,
        PrivaLoaderModule,
        PrivaSpinnerModule,
        DpDatePickerModule,
        PrivaDatepickerModule,
    ],
    templateUrl: './crops-dashboard.component.html',
    styleUrl: './crops-dashboard.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropsDashboardComponent implements OnInit {
    private readonly datePickerService = inject(DatePickerService);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly _store: Store = inject(Store);
    private readonly analytics: PrivaSegmentService = inject(PrivaSegmentService);
    private readonly _localizationService = inject(PrivaLocalizationService);
    public readonly router: Router = inject(Router);

    private today = dayjs();
    private yesterday = dayjs().subtract(1, 'day');

    /**
     * The following signals are used for data requests and to identify and set the date picker.
     */
    private selectedRequestDate = signal(this.yesterday);
    public selectedRequestDateRange: WritableSignal<DateRangeType> = signal(DateRange.DAILY);
    public dateRequest = computed(
        () =>
            <DateRequest>{
                dateRange: this.selectedRequestDateRange(),
                date: this.selectedRequestDate(),
            },
    );
    /**
     * Date picker variables and configurations
     ** The dayDatepicker use the DatePickerService format, but to render the week display, the weekdatepicker overides the string returnedValue and use fomatters to display data.
     */
    public dayDatePickerConfig: IDatePickerConfig;
    public weekDatePickerConfig: IDatePickerConfig;
    //used to rerender weekDatePickerConfig, not needed for dayDatePicker as the format does not need to be overriden
    public isConfigReady = signal(false);
    public placeholderDate = signal(this.yesterday.format(DateFormat));
    public displayDatePicker: WritableSignal<Dayjs> = signal(this.yesterday);
    // isMonday and cropEarliestStartDate are used to set the min and max value in datepickers
    private isMonday = signal(this.today.isoWeekday() === 1);
    private cropsEarliestStartDate = this._store.selectSignal(selectCurrentCropsEarliestStartDate);
    /**
     * The following variables are used in the tab day/week selection
     */
    public items = computed(() => list);
    public selectedItem: WritableSignal<PrivaSelectionGroupItem> = signal(this.items()[0]);
    /**
     * The following variables are used in the table
     */
    public dashboardData = this._store.selectSignal(selectDashboardData);
    public isLoading = this._store.selectSignal(selectIsLoading);
    public tooltipTable: Event[] = [];

    constructor() {
        this._localizationService.language.pipe(take(1)).subscribe((language: string) => {
            dayjs.locale(language.substring(0, 2));
        });
    }

    public ngOnInit(): void {
        this.generateDatePickers();
        this.isConfigReady.set(true);
    }

    public onDateChange(selectedDate: Dayjs): void {
        this.isConfigReady.set(false);
        if (selectedDate) {
            if (this.selectedItem().id === DateRange.WEEKLY) {
                this.displayDatePicker.set(selectedDate);
                this.placeholderDate.set(this.getWeekDateFormat(selectedDate));
                this.selectedRequestDate.set(this.getWeekStartDate(selectedDate));
                this.updateWeekDatePicker();
            } else {
                this.selectedRequestDate.set(selectedDate);
                this.placeholderDate.set(selectedDate.format(DateFormat));
            }

            this._store.dispatch(
                loadKPIsIfNeeded({
                    cropIds: this.dashboardData()?.cropIds,
                    kpiIds: this.dashboardData()?.kpiIds,
                    dateRequest: this.dateRequest(),
                }),
            );
            this.isConfigReady.set(true);
        }
    }

    private generateDatePickers(): void {
        const minDate = this.cropsEarliestStartDate();
        const maxDate = this.yesterday;
        this.dayDatePickerConfig = this.datePickerService.createConfig({
            dayBtnFormatter: (day: dayjs.Dayjs) => day.format(DayFormat),
            monthFormatter: (day: dayjs.Dayjs) => day.format(MonthYearFormat),
            monthBtnFormatter: (day: dayjs.Dayjs) => day.format(MonthFormat),
            yearFormatter: (day: dayjs.Dayjs) => day.format(YearFormat),
            returnedValueType: ECalendarValue.Dayjs,
            min: minDate,
            max: maxDate,
            locale: dayjs.locale(),
        } as IDatePickerConfig);

        this.weekDatePickerConfig = this.datePickerService.createConfig({
            format: this.getWeekDateFormat(),
            dayBtnFormatter: (day: dayjs.Dayjs) => day.format(DayFormat),
            monthFormatter: (day: dayjs.Dayjs) => day.format(MonthYearFormat),
            monthBtnFormatter: (day: dayjs.Dayjs) => day.format(MonthFormat),
            yearFormatter: (day: dayjs.Dayjs) => day.format(YearFormat),
            returnedValueType: ECalendarValue.Dayjs,
            min: minDate,
            max: maxDate,
            locale: dayjs.locale(),
        } as IDatePickerConfig);
    }

    public onToggleChange(item: PrivaSelectionGroupItem) {
        if (item.id !== this.selectedItem().id) this.selectedItem.set(item);
        this.updateDates(item.id as DateRangeType);
        this._store.dispatch(
            loadKPIsIfNeeded({
                cropIds: this.dashboardData()?.cropIds,
                kpiIds: this.dashboardData()?.kpiIds,
                dateRequest: this.dateRequest(),
            }),
        );
    }

    private updateDates(id: DateRangeType) {
        const date = this.getDefaultDate(id);
        this.displayDatePicker.set(dayjs(date));
        this.selectedRequestDateRange.set(id);
        this.selectedRequestDate.set(date);
        this.placeholderDate.set(
            id === DateRange.DAILY ? dayjs(date).format(DateFormat) : this.getWeekDateFormat(date),
        );
    }

    private getDefaultDate(dateRange: DateRangeType) {
        switch (dateRange) {
            case DateRange.WEEKLY:
                return this.isMonday() ? this.today.subtract(1, 'week') : this.today.startOf('isoWeek');
            case DateRange.DAILY:
            default:
                return this.yesterday;
        }
    }

    private getWeekStartDate(selectedDate: Dayjs) {
        return selectedDate.startOf('isoWeek');
    }

    public onSelectCrop({ cropId }) {
        this.analytics.track('Crop Row Clicked', {
            cropperformance_dashboard_crop_id: cropId,
        });
        this.router.navigate([`crops/dashboard/${cropId}/kpis`]);
    }

    private updateWeekDatePicker() {
        this.weekDatePickerConfig = {
            ...this.weekDatePickerConfig,
            format: this.getWeekDateFormat(),
        };

        this.cdr.detectChanges();
    }

    private getWeekDateFormat(date = this.selectedRequestDate()) {
        const endOfWeek = this.yesterday.isBefore(date.endOf('isoWeek'))
            ? this.yesterday
            : date.endOf('isoWeek');
        return `${this._localizationService.instant('APP.DATE.WEEK')} ${date.isoWeek()} (${date.startOf('isoWeek').format(DayMonthFormat)} - ${endOfWeek.format(DayMonthFormat)})`;
    }
}
