import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CropMetricsApiService } from '@app/analysis';
import { KpiTargetsService } from '@app/monitoring';
import { nameof } from '@app/utilities';

import { KpiDeviationsApiEffects } from './kpi-deviations-api.effects';
import { KpiDeviationsEffects } from './kpi-deviations.effects';
import { kpiDeviationsReducer } from './kpi-deviations.reducer';
import { KpiDeviationsStateContainer } from './kpi-deviations.state.model';

@NgModule({
    imports: [
        StoreModule.forFeature(
            nameof<KpiDeviationsStateContainer>('activeCropsKpiDeviations'),
            kpiDeviationsReducer,
        ),
        EffectsModule.forFeature([KpiDeviationsEffects, KpiDeviationsApiEffects]),
    ],
    providers: [CropMetricsApiService, KpiTargetsService],
})
export class KpiDeviationsStoreModule {}
