<div class="crops-header">
    <priva-filter class="filter">
        @if (filterButtonsConfig.length > 0) {
            <priva-filter-buttons
                [filterConfig]="filterButtonsConfig"
                [displayConfig]="cropsListFilterConfig.filterButtonsDisplayConfig"
                [dialogDisplayConfig]="cropsListFilterConfig.filterDialogDisplayConfig"
                [alwaysShowFilterDialog]="true"
                [dataSource]="dataSource"
                theme="onwhite"
            />
        }
    </priva-filter>

    @if (window.innerWidth > breakpointSm) {
        <div class="crop-action-toolbar">
            <button
                priva-button
                importance="primary"
                iconClass="far fa-plus"
                iconState="{{ window.innerWidth >= breakpointSm ? 'left' : 'only' }}"
                theme="onwhite"
                data-test="add-crop"
                (click)="showCropWizard = !showCropWizard"
            >
                {{ window.innerWidth >= breakpointSm ? ('APP.CROPS.ADD' | translate) : '' }}
            </button>
        </div>
    }
</div>

@if (showCropWizard) {
    <app-crop-wizard (close)="showCropWizard = false" />
}
