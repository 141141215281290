@if (crops$ | async; as crops) {
    @if (crops.length > 0) {
        <priva-content-section class="margin-bottom-2">
            <priva-title
                size="md"
                title="{{ title | translate }}"
                theme="onlightgray"
                [iconCircle]="false"
                [iconClass]="iconClass"
            />
            <priva-table
                [rows]="crops"
                [disableSelect]="true"
                [hasClickableRows]="true"
                type="separate"
                rowClickRole="button"
                class="flex-width-full"
                (rowClick)="onCropClick($event)"
            >
                <ng-template
                    priva-table-column="{{
                        window.innerWidth <= breakpointSmMid ? '' : ('APP.CROPS_TABLE.NAME' | translate)
                    }}"
                    justify="center"
                    width="500"
                    let-item="row"
                    [attr.maxWidth]="window.innerWidth < breakpointSm ? '0' : null"
                    id="name"
                >
                    <div class="table-item-container">
                        <span
                            ><strong>{{ item.name }}</strong></span
                        >
                        @if (window.innerWidth < breakpointMdMid) {
                            <div class="location-container">
                                <div class="location">
                                    {{ item.site?.name }}&nbsp;/&nbsp;{{ item.greenhouse?.name }}
                                </div>
                            </div>
                        }
                    </div>
                </ng-template>
                @if (window.innerWidth >= breakpointMdMid) {
                    <ng-template
                        priva-table-column="{{ 'APP.CROPS_TABLE.LOCATION' | translate }}"
                        justify="center"
                        width="500"
                        let-item="row"
                        id="location"
                    >
                        <div>{{ item.site?.name }} / {{ item.greenhouse?.name }}</div>
                    </ng-template>
                }
                @if (window.innerWidth >= breakpointSm) {
                    <ng-template
                        priva-table-column="{{
                            (window.innerWidth >= breakpointLg
                                ? 'APP.CROPS_TABLE.START_DATE'
                                : window.innerWidth <= breakpointSmMid
                                  ? ''
                                  : ('APP.CROPS_TABLE.DATE' | translate)
                            ) | translate
                        }}"
                        justify="center"
                        width="168"
                        let-item="row"
                        id="start-date"
                    >
                        <div class="table-item-container">
                            @if (window.innerWidth >= breakpointLg) {
                                <span>{{ item.startDateKey | dateKeyToString }}</span>
                            } @else {
                                <span>
                                    {{ 'APP.CROPS_TABLE.DATE_FROM' | translate }}:
                                    {{ item.startDateKey | dateKeyToString }}
                                </span>
                                <span>
                                    {{ 'APP.CROPS_TABLE.DATE_UNTIL' | translate }}:
                                    {{ item.endDateKey | dateKeyToString }}
                                </span>
                            }
                        </div>
                    </ng-template>
                }
                @if (window.innerWidth >= breakpointLg) {
                    <ng-template
                        priva-table-column="{{ 'APP.CROPS_TABLE.END_DATE' | translate }}"
                        justify="center"
                        width="168"
                        let-item="row"
                        id="end-date"
                    >
                        <div>{{ item.endDateKey | dateKeyToString }}</div>
                    </ng-template>
                }
                @if (window.innerWidth >= breakpointSmMid) {
                    <ng-template
                        priva-table-column="{{ 'APP.CROPS_TABLE.CROP_TYPE' | translate }}"
                        justify="center"
                        width="168"
                        let-item="row"
                        id="crop-type"
                    >
                        <div class="table-item-container">
                            <span>{{ cropTypeLabels[item.cropType] | translate }}</span>
                            @if (window.innerWidth < breakpointLg) {
                                <span>{{ cropSegmentLabels[item.cropSubType] | translate }}</span>
                            }
                        </div>
                    </ng-template>
                }
                @if (window.innerWidth >= breakpointLg) {
                    <ng-template
                        priva-table-column="{{ 'APP.CROPS_TABLE.CROP_SEGMENT' | translate }}"
                        justify="center"
                        width="168"
                        let-item="row"
                        id="crop-segment"
                    >
                        <div>{{ cropSegmentLabels[item.cropSubType] | translate }}</div>
                    </ng-template>
                }
                @if (window.innerWidth >= breakpointSmMid) {
                    <ng-template priva-table-column justify="center" width="50" let-item="row" id="action">
                        <app-crop-action-menu [crop]="item" priva-toggle-stop-click-propagation />
                    </ng-template>
                }
            </priva-table>
        </priva-content-section>
    }
}
