import { createAction, props } from '@ngrx/store';

import { DateRequest, KpiDeviations } from './kpi-deviations.state.model';

export const loadKPIsIfNeeded = createAction(
    '[Crop] Load KPIs If Needed',
    props<{ cropIds: string[]; kpiIds: string[]; dateRequest: DateRequest }>(),
);

export const loadCropKPIs = createAction(
    '[Crop] Load KPIs',
    props<{ cropIds: string[]; kpiIds: string[]; dateRequest: DateRequest }>(),
);
export const loadCropKPIsSuccess = createAction(
    '[Crop] Load KPIs Success',
    props<{ kpis: KpiDeviations[]; dateRequest: DateRequest }>(),
);

export const loadCropKPIsFailure = createAction('[Crop] Load KPIs Failure', props<{ error: any }>());

export const setCropKPIs = createAction(
    '[Crop] Set KPIs',
    props<{ kpis: KpiDeviations[]; dateRequest: DateRequest }>(),
);

export const setCropKPIsIsLoading = createAction(
    '[Crop] Set KPIs isLoading',
    props<{ isLoading: boolean }>(),
);

export const deleteCropKPIs = createAction('[Crop] Delete Crop KPIs', props<{ cropIds: string[] }>());
