import { Pipe, PipeTransform } from '@angular/core';

import { PrivaColorCategoryType } from '@priva/styles/foundation';

@Pipe({
    name: 'valueToColor',
    standalone: true,
})
export class ValueToColorPipe implements PipeTransform {
    transform(value: number): PrivaColorCategoryType {
        //TODO:  Add logic to determine color based on value once design is finalized
        const valueDeviation = value;
        return 'red';
    }
}
