import { createReducer, on } from '@ngrx/store';

import { deleteCropKPIs, setCropKPIs, setCropKPIsIsLoading } from './kpi-deviations.actions';
import { initialKpiDeviations } from './kpi-deviations.state.model';

export const kpiDeviationsReducer = createReducer(
    initialKpiDeviations,
    on(setCropKPIsIsLoading, (state, { isLoading }) => ({ ...state, isLoading })),
    on(setCropKPIs, (state, { kpis, dateRequest }) => ({
        ...state,
        cropsKpiDeviations: kpis,
        cropKpiDeviationsRange: dateRequest,
    })),
    on(deleteCropKPIs, (state, { cropIds }) => ({
        ...state,
        cropsKpiDeviations: state.cropsKpiDeviations.filter(
            (cropKpi) => !cropIds.includes(cropKpi.request.cropId),
        ),
    })),
);
