import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { KpiDeviationsApiService } from '@app/monitoring';

import { loadCropKPIs, loadCropKPIsFailure, loadCropKPIsSuccess } from './kpi-deviations.actions';
import { CropKpisResponse, KpiDeviations, KpiDeviationsError } from './kpi-deviations.state.model';

dayjs.extend(isoWeek);

@Injectable({ providedIn: 'root' })
export class KpiDeviationsApiEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _kpiDeviationsApiService = inject(KpiDeviationsApiService);

    /**
     * This effect will request the crop's KPI's from the backend one crop at a time.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */

    public loadCropKPIs$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropKPIs),
            mergeMap((action) => {
                let observables: Observable<CropKpisResponse>[];
                const date = action.dateRequest.date;
                const dateRange = action.dateRequest.dateRange;
                if (dateRange === 'daily') {
                    observables = action.cropIds.map((cropId) =>
                        this._kpiDeviationsApiService
                            .getDayKpiDeviations(date, cropId, action.kpiIds)
                            .pipe(catchError((error) => of({ error, cropId } as KpiDeviationsError))),
                    );
                } else {
                    observables = action.cropIds.map((cropId) =>
                        this._kpiDeviationsApiService
                            .getWeekKpiDeviations(date, cropId, action.kpiIds)
                            .pipe(catchError((error) => of({ error, cropId } as KpiDeviationsError))),
                    );
                }

                return forkJoin(observables).pipe(
                    map((results) => {
                        const successfulResults = results.filter(this.isSuccess);
                        const errors = results.filter(
                            (item: KpiDeviations) => !successfulResults.includes(item),
                        );

                        if (errors.length > 0) {
                            return loadCropKPIsFailure({ error: errors });
                        }

                        return loadCropKPIsSuccess({
                            kpis: successfulResults,
                            dateRequest: action.dateRequest,
                        });
                    }),
                    catchError((error) => of(loadCropKPIsFailure({ error }))),
                );
            }),
        );
    });

    // Type guard to determine if the response is a successful result
    private isSuccess(response: CropKpisResponse): response is KpiDeviations {
        return !('error' in response);
    }
}
