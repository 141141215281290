import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { EMPTY, mergeMap, of, withLatestFrom } from 'rxjs';

import { deleteCropSuccess } from '@priva/masterdata';

import {
    deleteCropKPIs,
    KpiDeviationsState,
    KpiDeviationsStateContainer,
    loadCropKPIs,
    loadCropKPIsSuccess,
    loadKPIsIfNeeded,
    setCropKPIs,
    setCropKPIsIsLoading,
} from '@app/crops';

@Injectable({ providedIn: 'root' })
export class KpiDeviationsEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _store: Store<KpiDeviationsStateContainer> = inject(Store);

    /**
     * This effect checks if the cropskpis are already in the store.
     * If not: then it will fire an action to really load the crops.
     */
    public loadKPIsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadKPIsIfNeeded),
            withLatestFrom(this._store.pipe(select((state) => state.activeCropsKpiDeviations))),
            mergeMap(([action, state]: [ReturnType<typeof loadKPIsIfNeeded>, KpiDeviationsState]) => {
                if (state.cropsKpiDeviations && isEqual(state.cropKpiDeviationsRange, action.dateRequest)) {
                    // Create a map with cropid and set of kpiIds from the state for fast lookup
                    const stateKpiIds = new Map<string, Set<string>>();
                    state.cropsKpiDeviations.forEach((crop) => {
                        const kpiIdsSet = new Set(crop.deviations.map((kpiValue) => kpiValue.kpiId));
                        stateKpiIds.set(crop.request.cropId, kpiIdsSet);
                    });

                    // Identify missing cropIds
                    const stateCropIds = new Set(state.cropsKpiDeviations.map((crop) => crop.request.cropId));
                    const missingCropIds = action.cropIds.filter((cropId) => !stateCropIds.has(cropId));

                    // Identify missing kpiIds for existing crops
                    const missingKpiIds: Record<string, string[]> = action.cropIds.reduce((acc, cropId) => {
                        const missingKpisForCrop = action.kpiIds.filter(
                            (kpiId) => !stateKpiIds.get(cropId)?.has(kpiId),
                        );
                        if (missingKpisForCrop.length > 0) {
                            acc[cropId] = missingKpisForCrop;
                        }
                        return acc;
                    }, {});

                    // Combine missing cropIds and missing kpiIds into a single load action
                    if (missingCropIds.length > 0 || Object.keys(missingKpiIds).length > 0) {
                        return [
                            setCropKPIsIsLoading({ isLoading: true }),
                            loadCropKPIs({
                                cropIds: missingCropIds,
                                kpiIds: Object.values(missingKpiIds).flat(),
                                dateRequest: action.dateRequest,
                            }),
                        ];
                    } else {
                        return EMPTY;
                    }
                } else {
                    // If there are no crops in the state, load all requested crops and kpis
                    return [
                        setCropKPIsIsLoading({ isLoading: true }),
                        loadCropKPIs({
                            cropIds: action.cropIds,
                            kpiIds: action.kpiIds,
                            dateRequest: action.dateRequest,
                        }),
                    ];
                }
            }),
        );
    });

    /**
     * This effect will take the CropKpis successfully loaded from the backend and convert it to KpiDeviations state used in the application.
     */
    public loadCropKPIsSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropKPIsSuccess),
            withLatestFrom(this._store.pipe(select((state) => state.activeCropsKpiDeviations))),
            mergeMap(([action, state]: [ReturnType<typeof loadCropKPIsSuccess>, KpiDeviationsState]) => {
                const kpis = action.kpis;
                const existingCropsKpis = state.cropsKpiDeviations || [];
                const updatedCropsKpis = structuredClone(existingCropsKpis);

                kpis.forEach((newKpi) => {
                    const cropIndex = updatedCropsKpis.findIndex(
                        (cropKpi) => cropKpi.request.cropId === newKpi.request.cropId,
                    );
                    if (cropIndex > -1) {
                        // Crop exists, update it
                        const existingKpiValuesList = updatedCropsKpis[cropIndex].deviations;
                        updatedCropsKpis[cropIndex].request = newKpi.request;
                        newKpi.deviations.forEach((newKpiValue) => {
                            const kpiIndex = existingKpiValuesList.findIndex(
                                ({ kpiId }) => kpiId === newKpiValue.kpiId,
                            );

                            if (kpiIndex > -1) {
                                // KPI exists, replace it
                                existingKpiValuesList[kpiIndex] = newKpiValue;
                            } else {
                                // KPI does not exist, add it
                                existingKpiValuesList.push(newKpiValue);
                            }
                        });
                    } else {
                        // Crop does not exist, add it
                        const updatedNewKPI = {
                            ...newKpi,
                            request: {
                                ...newKpi.request,
                            },
                        };
                        updatedCropsKpis.push(updatedNewKPI);
                    }
                });
                return [
                    setCropKPIs({ kpis: updatedCropsKpis, dateRequest: action.dateRequest }),
                    setCropKPIsIsLoading({ isLoading: false }),
                ];
            }),
        );
    });

    /**
     * This effect will delete the cropsKpiDeviations from the store when a crop gets deleted.
     */

    public deleteCropKPIsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(deleteCropSuccess),
            withLatestFrom(this._store.pipe(select((state) => state.activeCropsKpiDeviations))),
            mergeMap(([action, state]) => {
                const cropIdDeleted = action.crop.id;
                const isDeletedCropActive = state.cropsKpiDeviations.some(
                    (crop) => crop.request.cropId === cropIdDeleted,
                );
                if (isDeletedCropActive) {
                    return of(deleteCropKPIs({ cropIds: [cropIdDeleted] }));
                } else {
                    return EMPTY;
                }
            }),
        );
    });
}
