import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, Observable } from 'rxjs';

import { PrivaButtonModule } from '@priva/components/button';
import { PrivaContentSectionModule } from '@priva/components/content-section';
import { PrivaIllustrationModule } from '@priva/components/illustration';
import { PrivaStatusGlobalModule } from '@priva/components/status-global';
import { CropState, selectExpandedCrops } from '@priva/masterdata';

import { CropWizardComponent } from '../crop-wizard/crop-wizard.component';

@Component({
    selector: 'app-crops-empty',
    templateUrl: './crops-empty.component.html',
    standalone: true,
    imports: [
        PrivaContentSectionModule,
        PrivaStatusGlobalModule,
        PrivaIllustrationModule,
        PrivaButtonModule,
        CropWizardComponent,
        AsyncPipe,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropsEmptyComponent implements OnInit {
    private readonly _store = inject(Store);

    public currentUpcomingCropsCount$: Observable<number>;
    public showCropWizard = false;

    public ngOnInit(): void {
        // Determine if there are current or upcoming crops available in the 'raw' cropslist.
        this.currentUpcomingCropsCount$ = this._store.select(selectExpandedCrops).pipe(
            filter((cropsWithLocation) => !!cropsWithLocation),
            map(
                (cropsWithLocation) =>
                    cropsWithLocation.filter(
                        (cropWithLocation) =>
                            cropWithLocation.state === CropState.Current ||
                            cropWithLocation.state === CropState.Upcoming,
                    ).length,
            ),
        );
    }
}
