import { createSelector } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';

import { selectCurrentCrops, selectExpandedCrops } from '@priva/masterdata';

import { roundToMetricDecimals } from '@app/metrics';
import { selectCropKpis } from '@app/monitoring';

import { selectCropsDeviationsByRange } from '../state';
import { CropKpi, DashboardData, RowKpi } from './crops-dashboard.model';

export const selectCurrentCropsEarliestStartDate = createSelector(
    selectCurrentCrops,
    (crops): Dayjs | undefined => {
        if (!crops) {
            return undefined;
        }
        return crops.reduce((acc, crop) => {
            return acc < dayjs(crop.startDateKey.toString()) ? acc : dayjs(crop.startDateKey.toString());
        }, dayjs());
    },
);

export const selectCurrentCropsKpis = createSelector(
    selectExpandedCrops,
    selectCropsDeviationsByRange,
    selectCropKpis,
    (crops, deviations, kpiDetails): CropKpi[] | undefined => {
        if (!crops || !deviations || !kpiDetails) {
            return undefined;
        }
        return deviations.map((kpiDev): CropKpi => {
            return {
                cropId: kpiDev.request.cropId,
                cropName: crops.find(({ id }) => id === kpiDev.request.cropId)?.name,
                deviations: kpiDev.deviations
                    .filter((kpiDeviation) => {
                        //filter out returned kpiDeviation that has not been defined in the KpiConfigs configs
                        const kpi = kpiDetails.find(({ id }) => id === kpiDeviation.kpiId);
                        return kpi !== undefined;
                    })
                    .map((kpiDeviation): RowKpi => {
                        const kpi = kpiDetails.find(({ id }) => id === kpiDeviation.kpiId);

                        return {
                            id: 'kpi',
                            name: kpi.shortLabel,
                            kpiId: kpiDeviation.kpiId,
                            unit: kpi.unit,
                            value: roundToMetricDecimals(kpiDeviation.value, kpiDeviation.kpiId),
                            date: kpiDev.request.localDate
                                ? kpiDev.request.localDate
                                : kpiDev.request.localYearWeek,
                            deviation: roundToMetricDecimals(kpiDeviation.deviation, kpiDeviation.kpiId),
                            isOutOfBand: kpiDeviation.isOutOfBand,
                            hasTarget: kpiDeviation.hasTarget,
                        };
                    }),
            };
        });
    },
);

export const selectDashboardData = createSelector(selectCurrentCropsKpis, (cropDeviations): DashboardData => {
    if (!cropDeviations) {
        return undefined;
    }

    const dashboardData = {
        cropRowData: [],
        columnData: [],
        cropIds: cropDeviations.map((crop) => crop.cropId),
        kpiIds: cropDeviations[0].deviations.map((kpi) => kpi.kpiId),
    };

    cropDeviations.forEach((crop) => {
        const row = {
            id: 'crop',
            name: crop.cropName,
            cropId: crop.cropId,
            isAllTargetsSet: crop.deviations.every((kpi) => kpi.hasTarget),
        };

        dashboardData.cropRowData.push([row, ...crop.deviations]);
    });

    if (dashboardData.columnData.length === 0) {
        dashboardData.columnData.push({
            alignment: 'start',
            id: 'crop',
            kpiId: '',
            title: 'APP.CROP_DASHBOARD.CROP',
            activeCrops: cropDeviations.length,
        });

        cropDeviations[0].deviations.forEach(({ kpiId, name, unit }) => {
            dashboardData.columnData.push({
                alignment: 'start',
                id: 'kpi',
                kpiId,
                title: name,
                unit,
            });
        });
    }

    return dashboardData;
});
