import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { PrivaDialogSimpleModule } from '@priva/components/dialog-simple';
import { PrivaWizardIndicatorModule, PrivaWizardStep } from '@priva/components/wizard/indicator';
import { PrivaWizardToolbarButton, PrivaWizardToolbarModule } from '@priva/components/wizard/toolbar';
import { createCropWithLocations, Crop, CropLocation } from '@priva/masterdata';

import { CropBasicInformationComponent, CropLocationComponent } from '@app/crop-action';

enum Step {
    basicInformation = 0,
    location = 1,
}

const basicInformationStep: PrivaWizardStep = {
    id: Step.basicInformation,
    title: 'APP.CROP_WIZARD.BASIC',
};

const locationStep: PrivaWizardStep = {
    id: Step.location,
    title: 'APP.CROP_WIZARD.LOCATION',
};

const nextButton: PrivaWizardToolbarButton = {
    id: 'next',
    importance: 'primary',
    text: 'GLOBAL.ACTION.NEXT',
    steps: [Step.basicInformation],
    disabled: true,
};

const previousButton: PrivaWizardToolbarButton = {
    id: 'previous',
    importance: 'secondary',
    text: 'GLOBAL.ACTION.PREVIOUS',
    steps: [Step.location],
};

const doneButton: PrivaWizardToolbarButton = {
    id: 'done',
    importance: 'primary',
    text: 'GLOBAL.ACTION.DONE',
    steps: [Step.location],
    disabled: true,
};

@Component({
    selector: 'app-crop-wizard',
    templateUrl: './crop-wizard.component.html',
    standalone: true,
    imports: [
        PrivaDialogSimpleModule,
        PrivaWizardIndicatorModule,
        CropBasicInformationComponent,
        CropLocationComponent,
        PrivaWizardToolbarModule,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropWizardComponent implements OnInit {
    private readonly _store = inject(Store);

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public close = new EventEmitter<void>();

    public buttons: PrivaWizardToolbarButton[];
    public Step = Step;

    public steps: PrivaWizardStep[] = [basicInformationStep, locationStep];
    public activeStepIndex: number;

    public crop: Crop;
    public locations: CropLocation[];

    public ngOnInit(): void {
        // We start with no crop. Basic-information will fill half of it, location will do the other half.
        this.crop = null;

        basicInformationStep.completed = false;
        locationStep.completed = false;
        this.goToStepBasicInformation();
    }

    public buttonClick(button: PrivaWizardToolbarButton) {
        switch (button.id) {
            case 'next':
                this.goToStepLocation();
                break;
            case 'previous':
                this.goToStepBasicInformation();
                break;
            case 'done':
                this.close.emit();
                this.createCrop();
                break;
        }
    }

    private goToStepBasicInformation(): void {
        this.activeStepIndex = Step.basicInformation;
        nextButton.hidden = false;
        this.buttons = [nextButton];
    }

    private goToStepLocation(): void {
        this.activeStepIndex = Step.location;
        previousButton.hidden = false;
        doneButton.hidden = false;
        this.buttons = [previousButton, doneButton];
        basicInformationStep.completed = true;
    }

    public onBasicInformationValidChange(basicInformation: { isValid: boolean; crop: Crop }) {
        // Always 'save' the crop information
        this.crop = basicInformation.crop;

        // We can continue to the nextstep if the information is valid
        nextButton.disabled = !basicInformation.isValid;
    }

    public onLocationValidChange(location: { isValid: boolean; crop: Crop; locations: CropLocation[] }) {
        // Always 'save' the crop information
        this.crop = location.crop;
        this.locations = location.locations;

        // We can continue to the nextstep if the information is valid
        doneButton.disabled = !location.isValid;
    }

    private createCrop() {
        this._store.dispatch(createCropWithLocations({ crop: this.crop, cropLocations: this.locations }));
    }
}
